import { AnimatePresence, motion } from 'framer-motion'
import cn from 'classnames'
import VoipWindow from './VoipWindow/VoipWindow'
import { useVoip, useVoipActions } from '../../hooks/voip'
import { usePusherEvent } from '../../hooks/pusher'
import { Call } from '@twilio/voice-sdk'
import { LeadFeed } from '../../types/lead_feed'

const VoipBar = () => {
  const { campaign, inboundCall, outboundCall } = useVoip()
  const { unregisterCallCampaign } = useVoipActions()

  // Unregister voip if the currently active call campaign was paused
  usePusherEvent({
    channel: 'private-voip',
    event: 'campaign-paused',
    onEvent: (pausedCampaign: LeadFeed) => {
      if (campaign && pausedCampaign.id === campaign.id) {
        unregisterCallCampaign()
      }
    },
  })

  const callActive =
    inboundCall?.status() === Call.State.Open ||
    outboundCall?.status() === Call.State.Open ||
    outboundCall?.status() === Call.State.Connecting

  return (
    <>
      {/* @ts-expect-error FIXME */}
      <AnimatePresence>
        <motion.div
          initial={{ y: 100, opacity: 0.9 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.2 }}
        >
          <div className="fixed bottom-0 z-20 flex h-[100px] w-full flex-col-reverse items-center">
            <div
              className={cn('fixed bottom-0 z-20 h-[18px] w-full', {
                'bg-[--colors-action]': !callActive,
                'bg-[--colors-positive]': callActive,
              })}
            />
            <VoipWindow />
          </div>
        </motion.div>
      </AnimatePresence>
    </>
  )
}

export default VoipBar
