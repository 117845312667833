import React, { Dispatch, useContext, useEffect, useReducer } from 'react'
import { Device, Call as TwilioCall, TwilioError } from '@twilio/voice-sdk'
import * as Sentry from '@sentry/react'
import backend from '../backend'
import VoipBar from '../AgentPortal/Voip/VoipBar'
import IdleTimer from '../AgentPortal/Voip/IdleTimer'
import { LeadFeed } from '../types/lead_feed'
import { useShouldVoipBeEnabled } from './queries/shouldVoipBeEnabled'
import { useNotification } from './notification'
import { useTenantConfig } from './TenantConfig'
import { UserLead } from '../types/user_lead'
import { useAuth } from '../components/AuthProvider/auth_provider'
import { useScreenSize } from './useScreenSize'
import { jwtDecode } from 'jwt-decode'

// TODO - this whole file needs a MAJOR refactor. It's a total mess.

// TODO - This VoipContext interface is completely redundant with VoipState. VoipContext should only expose voipState and voipDispatch. Get rid of all of these fields.
interface VoipContext {
  devMode: boolean
  device?: Device
  campaign?: LeadFeed
  userLead?: UserLead
  inboundCall?: TwilioCall
  inboundCallAccepted: boolean
  outboundCall?: TwilioCall
  voipAllowed: boolean
}

const context = React.createContext<VoipContext>({
  devMode: false,
  inboundCallAccepted: false,
  voipAllowed: false,
})

export const useVoip = () => useContext(context)

// TODO - there is no need at all for this VoipActionContext. Simply expose voipDispatch through the VoipProvider. These actions should be defined in the reducer.
interface VoipActionContext {
  registerCallCampaign: (campaignId: string) => void
  unregisterCallCampaign: () => void
  makeOutboundCall: ({
    userLead,
    agentTwilioNumber,
    userLeadNumber,
  }: {
    userLead: UserLead
    agentTwilioNumber: string
    userLeadNumber: string
  }) => void
  setDevMode: (devMode: boolean) => void
  triggerDevModeInboundCall: () => void
  clearInboundCall: () => void
  clearOutboundCall: () => void
}

const actionsContext = React.createContext<VoipActionContext>({
  registerCallCampaign: () => {},
  unregisterCallCampaign: () => {},
  makeOutboundCall: () => {},
  setDevMode: () => {},
  triggerDevModeInboundCall: () => {},
  clearInboundCall: () => {},
  clearOutboundCall: () => {},
})
export const useVoipActions = () => useContext(actionsContext)

interface VoipState {
  /** Whether VoIP is allowed for the current user's browser. VoIP is **NOT ALLOWED** on mobile devices unless voice is enabled in the tenant config.
   *
   * NOTE: just because VoIP is allowed doesn't mean that a VoIP connection will be established. There are additional conditions that must be met that depend on the current VoIP state. E.g. if the user is in dev mode or if the user has an active call campaign.
   */
  voipAllowed: boolean
  devMode: boolean
  device?: Device
  campaignId?: string
  inboundCall?: TwilioCall
  inboundCallAccepted: boolean
  outboundCall?: TwilioCall
  context?: {
    campaign?: LeadFeed
    userLead?: UserLead
  }
  shouldRefreshToken: boolean
  error?: string
}

let defaultDevMode = process.env.NODE_ENV === 'development'
if (process.env.REACT_APP_DEFAULT_DEV_MODE === 'false') defaultDevMode = false
const sessionDevMode = sessionStorage.getItem('voip-dev-mode')
if (sessionDevMode === 'true') defaultDevMode = true
if (sessionDevMode === 'false') defaultDevMode = false

const defaultVoipState: VoipState = {
  devMode: defaultDevMode,
  inboundCallAccepted: false,
  shouldRefreshToken: false,
  voipAllowed: false,
}

export enum VoipActionType {
  SetDevMode = 'set_dev_mode',
  SetDevice = 'set_device',
  SetCampaignId = 'set_campaign_id',
  SetShouldRefreshToken = 'set_should_refresh_token',
  SetAudioDevices = 'set_audio_devices',
  SetContext = 'set_context',
  ClearContext = 'clear_context',
  SetError = 'set_error',
  SetInboundCall = 'set_inbound_call',
  SetOutboundCall = 'set_outbound_call',
  EndCall = 'end_call',
}

type VoipAction =
  | { type: VoipActionType.SetDevMode; devMode: VoipState['devMode'] }
  | { type: VoipActionType.SetDevice; device: VoipState['device'] }
  | { type: VoipActionType.SetCampaignId; campaignId?: VoipState['campaignId'] }
  | {
      type: VoipActionType.SetShouldRefreshToken
      shouldRefreshToken: VoipState['shouldRefreshToken']
    }
  | { type: VoipActionType.SetAudioDevices }
  | { type: VoipActionType.SetContext; context: VoipState['context'] }
  | { type: VoipActionType.ClearContext }
  | { type: VoipActionType.SetError; error?: string }
  | {
      type: VoipActionType.SetInboundCall
      inboundCall?: VoipState['inboundCall']
      inboundCallAccepted?: boolean
    }
  | {
      type: VoipActionType.SetOutboundCall
      outboundCall?: VoipState['outboundCall']
    }
  | {
      type: VoipActionType.EndCall
      outboundCall?: undefined
      inboundCall?: undefined
    }

// TODO - refactor to use the standard "payload" pattern
// TODO - this file is HUGE - move the reducer to a separate file
const reducer = (state: VoipState, action: VoipAction): VoipState => {
  switch (action.type) {
    case VoipActionType.SetDevMode:
      sessionStorage.setItem('voip-dev-mode', action.devMode ? 'true' : 'false')
      return {
        ...state,
        devMode: action.devMode,
      }
    case VoipActionType.SetDevice:
      return {
        ...state,
        device: action.device,
      }
    case VoipActionType.SetCampaignId:
      return {
        ...state,
        campaignId: action.campaignId,
      }
    case VoipActionType.SetShouldRefreshToken:
      return {
        ...state,
        shouldRefreshToken: action.shouldRefreshToken,
      }
    case VoipActionType.SetContext:
      return {
        ...state,
        context: {
          ...state.context,
          ...action.context,
        },
      }
    case VoipActionType.ClearContext:
      return {
        ...state,
        context: undefined,
      }
    case VoipActionType.SetError:
      return {
        ...state,
        error: action.error,
      }
    case VoipActionType.SetInboundCall:
      return {
        ...state,
        inboundCall: action.inboundCall,
        inboundCallAccepted: action.inboundCallAccepted ?? state.inboundCallAccepted,
      }
    case VoipActionType.SetOutboundCall:
      return {
        ...state,
        outboundCall: action.outboundCall,
      }
    case VoipActionType.EndCall:
      return {
        ...state,
        inboundCall: undefined,
        outboundCall: undefined,
      }
    default:
      return state
  }
}

// TODO - move dev mode things to a separate file
// Fake Twilio inbound call for dev mode
class DevModeInboundCall {
  state: TwilioCall.State
  muted: boolean = false
  parameters: Record<string, string>
  dispatch: Dispatch<VoipAction>

  constructor(_dispatch: Dispatch<VoipAction>) {
    this.state = TwilioCall.State.Pending
    this.parameters = {
      From: '+18598675309',
    }
    this.dispatch = _dispatch
  }

  status() {
    return this.state
  }

  accept() {
    this.state = TwilioCall.State.Open
    this.dispatch({
      type: VoipActionType.SetInboundCall,
      inboundCall: this as unknown as TwilioCall,
      inboundCallAccepted: true,
    })
  }

  reject() {
    this.state = TwilioCall.State.Closed
    this.dispatch({
      type: VoipActionType.SetInboundCall,
      inboundCall: this as unknown as TwilioCall,
      inboundCallAccepted: false,
    })
  }

  disconnect() {
    this.state = TwilioCall.State.Closed
    this.dispatch({
      type: VoipActionType.SetInboundCall,
      inboundCall: this as unknown as TwilioCall,
    })
  }

  mute(shouldMute?: boolean) {
    this.muted = shouldMute ?? true
    this.dispatch({
      type: VoipActionType.SetInboundCall,
      inboundCall: this as unknown as TwilioCall,
    })
  }

  isMuted() {
    return this.muted
  }

  sendDigits(key: string) {
    console.log(`sent key ${key}`)
  }
}

// Fake Twilio outbound call for dev mode
class DevModeOutboundCall {
  state: TwilioCall.State
  muted: boolean = false
  customParameters: Map<string, string>
  dispatch: Dispatch<VoipAction>

  constructor(_dispatch: Dispatch<VoipAction>) {
    this.state = TwilioCall.State.Open
    // Outbound parameters are in a map called customParameters
    this.customParameters = new Map()
    this.customParameters.set('From', '+18598675309')
    this.customParameters.set('To', '+18596636512') // In prod, this is where we'll grab the userLead's number from
    this.dispatch = _dispatch
  }

  status() {
    return this.state
  }

  accept() {
    this.state = TwilioCall.State.Open
    this.dispatch({
      type: VoipActionType.SetOutboundCall,
      outboundCall: this as unknown as TwilioCall,
    })
  }

  reject() {
    this.state = TwilioCall.State.Closed
    this.dispatch({
      type: VoipActionType.SetOutboundCall,
      outboundCall: this as unknown as TwilioCall,
    })
  }

  disconnect() {
    this.state = TwilioCall.State.Closed
    this.dispatch({
      type: VoipActionType.SetOutboundCall,
      outboundCall: this as unknown as TwilioCall,
    })
  }

  mute(shouldMute?: boolean) {
    this.muted = shouldMute ?? true
    this.dispatch({
      type: VoipActionType.SetOutboundCall,
      outboundCall: this as unknown as TwilioCall,
    })
  }

  isMuted() {
    return this.muted
  }

  setError() {
    this.state = TwilioCall.State.Closed
    this.dispatch({ type: VoipActionType.SetError, error: 'There was an error' })
    this.dispatch({ type: VoipActionType.ClearContext })
  }

  sendDigits(key: string) {
    console.log(`sent key ${key}`)
  }
}

// TODO getNewVoipToken should be an action in the reducer (and exposed through voipDispatch)
/**
 * Fetches a new VoIP token from our API
 */
const getNewVoipToken = async (): Promise<string> => {
  const { body } = await backend.get('/voip/token')
  const token = body.token
  Sentry.captureMessage('Generate new VoIP token', { level: 'info', extra: { token } })
  localStorage.setItem('voip-device-token', token)
  return token
}

/**
 * Checks if the token is properly formatted and not expired
 */
const tokenIsValid = (token: string): boolean => {
  try {
    const decoded = jwtDecode(token)
    const exp = decoded.exp
    return typeof exp === 'number' && exp > Date.now() / 1000
  } catch (e) {
    console.error('Error decoding token', e)
    return false
  }
}

/**
 * Fetches a VoIP token from local storage, if it exists, if not, fetches a new one from our API
 */
const getVoipToken = async (): Promise<string> => {
  const token = localStorage.getItem('voip-device-token')
  if (token && tokenIsValid(token)) {
    Sentry.captureMessage('Use existing VoIP token from session storage', {
      level: 'info',
      extra: { token },
    })

    return token
  }
  const newToken = await getNewVoipToken()
  return newToken
}

// TODO - initDevice should be an action in the reducer (and exposed through voipDispatch)
/**
 * Initializes the Twilio device and adds several event listeners to it
 */
const initDevice = async ({
  dispatch,
  supportEmail,
}: {
  dispatch: React.Dispatch<VoipAction>
  supportEmail: string
}) => {
  const token = await getVoipToken()
  Sentry.setContext('deviceToken', { token })
  const device = new Device(token, {
    logLevel: 1,
    enableImprovedSignalingErrorPrecision: true,
  })

  const connectionErrorMessage =
    'The connection that allows calls to be taken has been lost. Please check your internet connection.'
  const browserNotSupportedErrorMessage =
    'Your browser is not supported. Please use the latest version of Chrome, Safari, Firefox, or Edge to receive calls.'

  // Confirm that the user's browser is supported
  if (!Device.isSupported) {
    // Browser is not supported, destroy the device and show an error
    device.destroy()

    dispatch({
      type: VoipActionType.SetError,
      error: browserNotSupportedErrorMessage,
    })
    Sentry.captureMessage('Browser not supported', {
      level: 'error',
      extra: { browser: navigator.userAgent },
    })

    return
  }

  // Register on page load so users can start receiving inbound calls automatically
  device.register()

  device.on('registered', () => dispatch({ type: VoipActionType.SetDevice, device }))
  device.on('unregistered', () => {
    // TODO: Handle unregistered - may need to re-register the device if this was unexpected
  })
  device.on('destroyed', () => {
    // TODO: Handle destroyed - may need to re-register the device if this was unexpected
  })
  // called when an inbound call comes in to the browser
  device.on('incoming', handleIncomingCall(dispatch))
  device.on('tokenWillExpire', () =>
    dispatch({ type: VoipActionType.SetShouldRefreshToken, shouldRefreshToken: true })
  )
  device.on('error', (error: TwilioError.TwilioError) => {
    dispatch({ type: VoipActionType.SetDevice, device })
    Sentry.setContext('deviceToken', { token: device.token })

    Sentry.captureException(error)
    // TODO - after we fix all of the connection issues, only send errors to Sentry if they're unexpected (i.e. if the code doesn't have a specific case below)

    switch (error.code) {
      // Handle specific error codes
      case 20101:
        // Access Token Invalid
        // https://www.twilio.com/docs/api/errors/20101#error-20101
        dispatch({ type: VoipActionType.SetShouldRefreshToken, shouldRefreshToken: true })
        break

      case 20104:
        // Access Token expired or expiration date invalid
        // https://www.twilio.com/docs/api/errors/20104#error-20104
        dispatch({ type: VoipActionType.SetShouldRefreshToken, shouldRefreshToken: true })
        break

      case 31000:
        // Unknown Error (Generic Twilio Connection Error)
        // https://www.twilio.com/docs/api/errors/31000#error-31000
        dispatch({
          type: VoipActionType.SetError,
          error: connectionErrorMessage,
        })
        break

      case 31005:
        // Connection error
        // https://www.twilio.com/docs/api/errors/31005#error-31005
        dispatch({
          type: VoipActionType.SetError,
          error: connectionErrorMessage,
        })
        break

      case 31009:
        // Transport error
        // https://www.twilio.com/docs/api/errors/31009#error-31009
        dispatch({
          type: VoipActionType.SetError,
          error: connectionErrorMessage,
        })
        break

      default:
        // TODO - handle all other errors - maybe display an error message...
        break
    }
  })

  dispatch({ type: VoipActionType.SetDevice, device: device })
}

// fetches a campaign from our API, so we have the information needed to display in the call window
const fetchCampaignContext = async (campaignId: string, dispatch: React.Dispatch<VoipAction>) => {
  const response = await backend.get(`/lead-feeds/${campaignId}`)
  dispatch({ type: VoipActionType.SetContext, context: { campaign: response.body } })
}

// adds event listeners to a new inbound call
const handleIncomingCall = (dispatch: React.Dispatch<VoipAction>) => (call: TwilioCall) => {
  const currentTitle = document.title
  document.title = '📞 INCOMING CALL'

  try {
    // send a fake error to Sentry to its easy to find when calls came in on Sentry replays
    Sentry.captureMessage('Incoming call', { level: 'info' })
    Sentry.setContext('callContext', {
      key: call.parameters.CallSid,
    })
  } catch (e) {
    console.log(e)
    Sentry.captureException(e)
  }

  console.log(call.status())

  dispatch({ type: VoipActionType.SetInboundCall, inboundCall: call })
  // Trigger state changes on call events
  // This way we can reliably use call.status() in our components
  call.on('accept', (call: TwilioCall) => {
    dispatch({
      type: VoipActionType.SetInboundCall,
      inboundCall: call,
      inboundCallAccepted: true,
    })
    document.title = currentTitle

    // Press 1 every second for 3 seconds
    setTimeout(() => {
      call.sendDigits('1')
      setTimeout(() => {
        call.sendDigits('1')
        setTimeout(() => {
          call.sendDigits('1')
        }, 1000)
      }, 1000)
    }, 1000)
  })
  call.on('cancel', () => {
    document.title = currentTitle
    dispatch({ type: VoipActionType.SetInboundCall })
  })
  call.on('reject', () => {
    document.title = currentTitle
    dispatch({ type: VoipActionType.SetInboundCall })
  })
  call.on('disconnect', (call: TwilioCall) => {
    document.title = currentTitle
    dispatch({ type: VoipActionType.SetInboundCall, inboundCall: call })
  })
  call.on('mute', (_: void, call: TwilioCall) =>
    dispatch({ type: VoipActionType.SetInboundCall, inboundCall: call })
  )
}

// hits our API on page load to check if a call campaign is currently active
const useCheckForActiveCallCampaign = ([state, dispatch]: VoipStateAndDispatch) => {
  const { data } = useShouldVoipBeEnabled()

  useEffect(() => {
    if (data?.enabled_call_campaign_id && !state.campaignId) {
      dispatch({ type: VoipActionType.SetCampaignId, campaignId: data.enabled_call_campaign_id })
    }
  }, [data, state.device])
}

// Initializes the Twilio Device when VoIP is allowed, when not in dev mode, and when the user has a call campaign that is currently enabled or voice is enabled
const useInit = ([state, dispatch]: VoipStateAndDispatch) => {
  const tenantConfig = useTenantConfig()

  useEffect(() => {
    if (state.voipAllowed && !state.devMode && (tenantConfig.voice.enabled || !!state.campaignId)) {
      // Voice is enabled or Call campaign is enabled so device should be registered
      if (state.device?.state !== Device.State.Registered) {
        // Device is not yet registered, so initialize it
        initDevice({ dispatch, supportEmail: tenantConfig.emails.support_email })
      }
    } else if (state.device?.state === Device.State.Registered) {
      // Call campaign was disabled and voice is not enabled, so destroy the device if it's still registered
      state.device.destroy()
    }
  }, [state.voipAllowed, state.devMode, state.context?.campaign?.enabled])
}

// calls fetchCampaignContext() when a campaignId is added to the state
const useRegisterCallCampaign = ([state, dispatch]: VoipStateAndDispatch) => {
  // Register when campaignId is set if not already registered (shouldn't happen since we register on page load but just in case)
  useEffect(() => {
    if (state.campaignId && state.device?.state === Device.State.Unregistered) {
      state.device.register()
    }
  }, [state.campaignId, state.device])

  // Fetch the campaign when campaignId is set
  useEffect(() => {
    if (state.campaignId) fetchCampaignContext(state.campaignId, dispatch)
  }, [state.campaignId])
}

// Fetch a fresh token when shouldRefreshToken is set to true
const useRefreshToken = ([state, dispatch]: VoipStateAndDispatch) => {
  const { user } = useAuth()
  useEffect(() => {
    if (state.shouldRefreshToken && state.voipAllowed) {
      if (state.device && !user?.impersonator?.id) {
        getNewVoipToken().then((token) => {
          Sentry.setContext('deviceToken', { token })
          Sentry.captureMessage('Refreshing VoIP token', {
            level: 'info',
            extra: { token },
          })
          state.device?.updateToken(token)
          dispatch({ type: VoipActionType.SetShouldRefreshToken, shouldRefreshToken: false })
        })
      } else {
        dispatch({ type: VoipActionType.SetShouldRefreshToken, shouldRefreshToken: false })
      }
    }
  }, [state.shouldRefreshToken, user])
}

// displays error toasts when an error is added to the state
const useDisplayError = ([state, dispatch]: VoipStateAndDispatch) => {
  const showNotification = useNotification()

  // Display an error notification when error is set
  useEffect(() => {
    if (state.error) {
      // @ts-expect-error FIXME
      showNotification({ type: 'error', message: state.error })
      dispatch({ type: VoipActionType.SetError })
    }
  }, [state.error])
}

type VoipStateAndDispatch = [VoipState, Dispatch<VoipAction>]

export const VoipProvider = ({ children }: { children: React.ReactNode }) => {
  const tenantConfig = useTenantConfig()
  const device = useScreenSize()
  const { user } = useAuth()

  // VoIP is not allowed if user is impersonating
  // VoIP is not allowed on mobile devices unless voice is enabled in the tenant config
  const voipAllowed =
    !user?.impersonator?.id &&
    (tenantConfig.voice.enabled ||
      (tenantConfig.campaigns.call_campaign_voip === true && device !== 'mobile'))

  const stateAndDispatch = useReducer(reducer, {
    ...defaultVoipState,
    voipAllowed,
  })

  // TODO - these hooks are using a very strange pattern. Refactor.
  useCheckForActiveCallCampaign(stateAndDispatch)
  useInit(stateAndDispatch)
  useRegisterCallCampaign(stateAndDispatch)
  useRefreshToken(stateAndDispatch)
  useDisplayError(stateAndDispatch)

  const [state, dispatch] = stateAndDispatch

  const value = {
    devMode: state.devMode,
    device: state.device,
    inboundCall: state.inboundCall,
    inboundCallAccepted: state.inboundCallAccepted,
    outboundCall: state.outboundCall,
    voipAllowed,
    ...state.context,
  }

  const registerCallCampaign = (campaignId: string) =>
    dispatch({ type: VoipActionType.SetCampaignId, campaignId })

  const unregisterCallCampaign = () => {
    dispatch({ type: VoipActionType.SetCampaignId })
    dispatch({ type: VoipActionType.ClearContext })
    if (!tenantConfig.voice.enabled) {
      // only call unregister if device.state is registered
      if (state.device?.state === Device.State.Registered) {
        state.device?.unregister()
      }
    }
  }

  const setDevMode = (devMode: boolean) => {
    sessionStorage.setItem('voip-dev-mode', devMode.toString())
    dispatch({ type: VoipActionType.SetDevMode, devMode })
  }

  const triggerDevModeInboundCall = () => {
    dispatch({
      type: VoipActionType.SetInboundCall,
      inboundCall: new DevModeInboundCall(dispatch) as unknown as TwilioCall,
    })
  }

  const triggerDevModeOutboundCall = ({ userLead }: { userLead: UserLead }) => {
    dispatch({ type: VoipActionType.SetContext, context: { userLead } })
    dispatch({
      type: VoipActionType.SetOutboundCall,
      outboundCall: new DevModeOutboundCall(dispatch) as unknown as TwilioCall,
    })
  }

  // initiates an outbound call and adds several event listeners to it
  const makeOutboundCall = async ({
    userLead,
    agentTwilioNumber,
    userLeadNumber,
  }: {
    userLead: UserLead
    agentTwilioNumber: string
    userLeadNumber: string
  }) => {
    if (!userLead) {
      console.error('Lead not found') // Might want to change this
      return
    }

    if (state.devMode) {
      return triggerDevModeOutboundCall({ userLead })
    }

    dispatch({ type: VoipActionType.SetContext, context: { userLead } })

    try {
      const device = state.device
      if (!device) throw new Error('Device is not registered') // Might want to change this

      const call = await device?.connect({
        params: {
          To: userLeadNumber,
          From: agentTwilioNumber,
          userLeadId: userLead.id,
        },
      })

      dispatch({ type: VoipActionType.SetOutboundCall, outboundCall: call })

      call.on('accept', () =>
        dispatch({
          type: VoipActionType.SetOutboundCall,
          outboundCall: call,
        })
      )
      call.on('cancel', () => dispatch({ type: VoipActionType.SetOutboundCall }))
      call.on('reject', () => dispatch({ type: VoipActionType.SetOutboundCall }))
      call.on('disconnect', (call: TwilioCall) =>
        dispatch({ type: VoipActionType.SetOutboundCall, outboundCall: call })
      )
      call.on('mute', (_: void, call: TwilioCall) =>
        dispatch({ type: VoipActionType.SetOutboundCall, outboundCall: call })
      )
      call.on('error', () => dispatch({ type: VoipActionType.SetError }))
    } catch (e: any) {
      console.error('Error making outbound call', e)
      dispatch({ type: VoipActionType.SetError, error: e.message })
    }
  }

  // removes the inbound call from state
  const clearInboundCall = () => {
    dispatch({ type: VoipActionType.SetInboundCall })
  }

  // removes the outbound call from state
  const clearOutboundCall = () => {
    dispatch({ type: VoipActionType.SetOutboundCall })
    dispatch({ type: VoipActionType.ClearContext })
  }

  const deviceIsRegistered = value.device?.state === Device.State.Registered || value.devMode
  const showVoipBar =
    deviceIsRegistered &&
    (Boolean(state.inboundCall) ||
      Boolean(value.userLead) ||
      Boolean(value.campaign?.product?.type === 'calls'))

  const actions = {
    registerCallCampaign,
    unregisterCallCampaign,
    makeOutboundCall,
    setDevMode,
    triggerDevModeInboundCall,
    clearInboundCall,
    clearOutboundCall,
  }

  return (
    <context.Provider value={value}>
      <actionsContext.Provider value={actions}>
        {children}
        {showVoipBar && <VoipBar />}
        <IdleTimer />
      </actionsContext.Provider>
    </context.Provider>
  )
}
